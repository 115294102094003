import { useEffect } from "react";

import { Button, Grid, TextField } from "@mui/material";

import SelectLocation from "~/components/select/SelectLocation";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { LocationDocument, LocationType } from "~/services/graphql";
import { closeAddSetDown, updateSetDownCount, updateSetDownLocation } from "~/store/count/setDownSlice";

interface SetDownAddProps {
	handleAddSetDown: (count: number, loccation: LocationDocument) => void;
}

const SetDownAdd = ({ handleAddSetDown }: SetDownAddProps) => {
	const dispatch = useAppDispatch();

	const count = useAppSelector((state) => state.count.setDown.count);

	const location = useAppSelector((state) => state.count.setDown.location);

	const handleLocationChange = (val: LocationDocument | null) => {
		dispatch(updateSetDownLocation(val));
	};

	const handleCancel = () => {
		dispatch(updateSetDownCount(0));
		dispatch(updateSetDownLocation(null));
		dispatch(closeAddSetDown());
	};

	useEffect(() => {
		console.log("count", count);
	}, [count]);
	return (
		<SpacedGridContainer>
			<SpacedGridItem maxCols={1} smMargin>
				<TextField label="Count" value={count || ""} onChange={(e) => dispatch(updateSetDownCount(parseInt(e.target.value)))} fullWidth />
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<SelectLocation handleInputChange={handleLocationChange} locationTypes={[LocationType.Bin]} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Grid container spacing={1} alignItems="stretch" direction="row">
					<Grid item xs>
						<Button variant="contained" onClick={() => count && location && handleAddSetDown(count, location)} fullWidth>
							Submit
						</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" color="inherit" onClick={handleCancel}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};

export default SetDownAdd;
