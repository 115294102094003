import { AppBar, Badge, Box, Icon, IconButton, Toolbar, Typography } from "@mui/material";

import Config from "~/config";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import PageButtons from "~/layout/PageButtons";
import { openDrawer } from "~/store/layoutSlice";

interface TopBarProps {
	children: JSX.Element;
}

const TopBar = ({ children }: TopBarProps) => {
	const dispatch = useAppDispatch();

	const pageTitle = useAppSelector((state) => state.layout.pageTitle);
	const updateAvailable = useAppSelector((state) => state.layout.updateAvailable);
	const showTopBar = useAppSelector((state) => state.layout.showTopBar);

	if (!showTopBar) {
		return <>{children}</>;
	}

	return (
		<AppBar id="appHeader" position="static">
			{Config.environment !== "production" ? (
				<Box
					sx={{
						fontSize: ".6em",
						fontweight: "bold",
						position: "absolute",
						textAlign: "center",
						width: "100%",
						backgroundColor: "warning.main",
						color: "warning.contrastText",
					}}
				>
					&nbsp;{Config.branch} - {Config.buildVersion}&nbsp;
				</Box>
			) : null}
			<Toolbar>
				<IconButton color="inherit" aria-label="open drawer" onClick={() => dispatch(openDrawer())} edge="start">
					<Badge color="error" variant="dot" invisible={!updateAvailable}>
						<Icon>menu</Icon>
					</Badge>
				</IconButton>

				<Typography variant="h6" noWrap>
					{pageTitle}
				</Typography>
				<PageButtons />
			</Toolbar>
			{children}
		</AppBar>
	);
};

export default TopBar;
