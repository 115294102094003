import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { updateActionLinks } from "~/store/layoutSlice";

const actionLinkMapping: { [key: string]: PageButton[] } = {
	"/$": [],
	"/settings": [],
	"/count/clipboard": [],
	"/count/": [
		{
			key: "lines",
			label: "Production Lines",
			link: "/count/lines",
			iconName: "monitor",
		},
		{
			key: "setdown",
			label: "Set Down",
			link: "/count/setdown",
			iconName: "warehouse_outlined",
		},
	],

	// Add more paths and titles as needed
};
// regex match return first match
const getActionLinks = (path: string) => {
	const match = Object.keys(actionLinkMapping).find((key) => {
		//console.log("key", key);
		return new RegExp(key).test(path);
	});
	//console.log("match", match);
	return actionLinkMapping[match || "/"];
};

const useActionMenu = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		//console.log("path", path);
		const actionLinks = getActionLinks(path);
		dispatch(updateActionLinks(actionLinks));
	}, [location, dispatch]);
};

export default useActionMenu;
