import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QueueItemState {
	id: string;
	operationName: string;
	groupKey: string;
	variables: any;
	displayData?: any;
	addedTimestamp?: number;
	updatedTimestamp?: number;
	status: string;
	result?: any;
	user?: string;
}

interface QueueState {
	queueItems: QueueItemState[];
}

const initialState: QueueState = {
	queueItems: [],
};

export const queueSlice = createSlice({
	name: "queue",
	initialState,
	reducers: {
		addQueueItem: (state, action: PayloadAction<QueueItemState>) => {
			action.payload.addedTimestamp = Date.now();
			let qi = [...state.queueItems, action.payload];
			state.queueItems = qi;
		},
		processQueueItem: (state) => {
			const index = state.queueItems.findIndex((item) => item.status === "pending");
			//process the item in the queue with state pending then update state
			if (index !== -1) {
				//let updatedTimestamp = Date.now();
				//state.queueItems[index] = action.payload;
			}
		},
		updateQueueItem: (state, action: PayloadAction<QueueItemState>) => {
			const index = state.queueItems.findIndex((item) => item.id === action.payload.id);
			//update the item in the queue with the same id then update state
			action.payload.updatedTimestamp = Date.now();
			if (index !== -1) {
				state.queueItems[index] = action.payload;
			}
		},
		removeQueueItem: (state, action: PayloadAction<QueueItemState>) => {
			//remove the item from the queue with the same id then update state
			const queue = state.queueItems.filter((item) => {
				return item.id !== action.payload.id;
			});
			state.queueItems = [...queue];
		},
	},
});

export const { addQueueItem, updateQueueItem, removeQueueItem } = queueSlice.actions;

export default queueSlice.reducer;
