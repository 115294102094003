import { useEffect } from "react";

import { Button, IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import { Link, useLocation } from "react-router-dom";

import ActionMenu from "~/components/ActionMenu";
import ErrorMenu from "~/components/ErrorMenu";
import { useAppSelector } from "~/hooks/reduxHooks";

function PageButtons() {
	const pageButtons = useAppSelector((state) => state.layout.pageButtons);
	const location = useLocation();

	useEffect(() => {
		location.pathname;
	}, [location]);

	//	<SiteMenu />
	return (
		<div style={{ marginLeft: "auto", display: "flex", flexDirection: "row-reverse" }}>
			<ErrorMenu />

			{pageButtons?.map((pageBtn: PageButton) =>
				pageBtn.disabled ? null : pageBtn.link ? (
					<Link
						key={pageBtn.key}
						to={pageBtn.link}
						title={pageBtn.label}
						style={{ whiteSpace: "nowrap", marginLeft: "0.5rem", textDecoration: "none", color: "inherit" }}
					>
						{pageBtn.iconName ? (
							<IconButton color="inherit">
								<Icon>{pageBtn.iconName}</Icon>
							</IconButton>
						) : (
							<Button size="small" color={pageBtn.active ? "secondary" : "primary"} variant="contained" key={pageBtn.key} title={pageBtn.label}>
								{pageBtn.label}
							</Button>
						)}
					</Link>
				) : pageBtn.label ? (
					<Button
						style={{ whiteSpace: "nowrap", marginLeft: "0.5rem" }}
						size="small"
						color={pageBtn.active ? "primary" : "secondary"}
						variant="contained"
						key={pageBtn.key}
						title={pageBtn.label}
					>
						{pageBtn.label}
					</Button>
				) : null
			)}
			<ActionMenu />
		</div>
	);
}

export default PageButtons;
