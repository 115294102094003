export const timestampFormatDateTime = (ts: number, mult: number = 1): string => {
	return new Date(ts * mult).toLocaleString();
};

export const timestampFormatTime = (ts: number, mult: number = 1): string => {
	return new Date(ts * mult).toLocaleString("default", {
		hour: "numeric",
		minute: "2-digit",
	});
};
export const timestampFormatTimeSec = (ts: number, mult: number = 1): string => {
	return new Date(ts * mult).toLocaleString("default", {
		hour: "numeric",
		minute: "2-digit",
		second: "2-digit",
	});
};

export const timestampFormatDate = (ts: number, mult: number = 1): string => {
	return new Date(ts * mult).toLocaleDateString();
};

export const timestampFormatDayTime = (ts: number, mult: number = 1): string => {
	return new Date(ts * mult).toLocaleString("default", {
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
	});
};

export const stringFormatDateTime = (date: string): string => {
	return new Date(date).toLocaleString();
};

export const stringFormatTime = (date: string): string => {
	return new Date(date).toLocaleString("default", {
		hour: "numeric",
		minute: "2-digit",
	});
};

export const stringFormatDayTime = (date: string): string => {
	return new Date(date).toLocaleString("default", {
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
	});
};

export const stringFormatDate = (date: string): string => {
	return new Date(date).toLocaleDateString();
};

export const stringFormatUnixTime = (date: string): number => {
	return Math.floor(new Date(date).getTime() / 1000);
};
