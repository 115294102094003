import { Box, Button, Container, Grid, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Config from "~/config";
import Footer from "~/layout/Footer";
import NavDrawer from "~/layout/NavDrawer";
import TopBar from "~/layout/TopBar";
import Router from "~/router";

const Layout = () => {
	const navigate = useNavigate();

	return (
		<>
			<TopBar>
				<NavDrawer />
			</TopBar>

			<Container
				maxWidth="xl"
				sx={{ padding: { xs: 0, md: 1 }, display: "flex", flexDirection: "column", justifyContent: "flex-start", flexGrow: 1, width: "100%" }}
			>
				<Router />
				<Footer>
					<Grid container spacing={2}>
						<Grid item xs>
							{window.history.state && window.history.state.idx > 0 && (
								<Button startIcon={<Icon>chevron_left</Icon>} variant="text" color="inherit" onClick={() => navigate(-1)}>
									Back
								</Button>
							)}
						</Grid>
						<Grid item>
							<Box sx={{ padding: "6px 16px" }}>{Config.releaseVersion}</Box>
						</Grid>
					</Grid>
				</Footer>
			</Container>
		</>
	);
};
export default Layout;
