export const themeOverrides = {
	MuiPaper: {
		styleOverrides: {
			elevation1: {
				boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
			},
		},
	},

	MuiContainer: {
		styleOverrides: {
			root: {
				width: "unset",
			},
			maxWidthXl: {
				"@media (min-width: 1920px)": {
					maxWidth: "1800px",
					width: "100%",
				},
			},
		},
	},

	MuiDialog: {
		styleOverrides: {
			root: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				backgroundImage: "none",
			},
			container: {
				//width: "calc(100% - 16px)",
				width: "100%",
				maxWidth: "800px",
			},
			paper: {
				width: "100%",
				margin: 2,
				maxWidth: "unset",
				maxHeight: "calc(100% - 32px)",
				overflow: "auto",
				backgroundImage: "none",
			},
		},
	},

	MuiDialogTitle: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},
	MuiIcon: {
		defaultProps: {
			// Replace the `material-icons` default value.
			baseClassName: "material-symbols-rounded",
		},
	},
};

export const themeOverridesDark = {
	MuiContainer: {
		styleOverrides: {
			root: {
				width: "unset",
			},
			maxWidthXl: {
				"@media (min-width: 1920px)": {
					maxWidth: "unset",
					width: "unset",
				},
			},
		},
	},
	MuiIcon: {
		defaultProps: {
			// Replace the `material-icons` default value.
			baseClassName: "material-symbols-rounded",
		},
	},
};
