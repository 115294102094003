import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

export const errorLoggingMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
	if (isRejectedWithValue(action)) {
		action.payload.error = true;
		let endpointName = action?.meta?.arg?.endpointName;
		let errorDetail = {
			message: "Api Error",
			detail: action.payload.message,
			code: endpointName || "Unknown",
		};
		api.dispatch({ type: "error/addError", payload: errorDetail });
		console.error("APi Error caught by middleware:", action.payload);
		// You can also dispatch another action here to handle the error globally
		// store.dispatch(showGlobalError(action.payload));
	}
	let endpointName = action?.meta?.arg?.endpointName;
	if (endpointName) {
		if (action.payload && action.payload[endpointName]?.errors) {
			action.payload.error = true;
			let errorDetail = {
				message: "Api Payload Error",
				detail: action.payload[endpointName]?.errors,
				code: endpointName,
			};
			api.dispatch({ type: "error/addError", payload: errorDetail });
			console.error("Api Payload Error caught by middleware:", action.payload);
		}
	}
	return next(action);
};
