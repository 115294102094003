import { useContext, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { debounce } from "lodash";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";

import { AuthContext } from "~/auth";
import Config from "~/config";
import { useAppDispatch } from "~/hooks/reduxHooks";
import useActionMenu from "~/hooks/useActionMenu";
import usePageButtons from "~/hooks/usePageButtons";
import usePageTitle from "~/hooks/usePageTitle";
import Layout from "~/layout/Layout";
import AppThemeProvider from "~/providers/AppThemeProvider";
import GraphQLProvider from "~/providers/GraphqlProvider";
import { updateViewType, ViewType } from "~/store/layoutSlice";

const App = () => {
	const authContext = useContext(AuthContext);
	usePageTitle();
	useActionMenu();
	usePageButtons();

	let dispatch = useAppDispatch();

	const handleWindowResize = () => {
		let newtype = window.innerWidth > 1000 ? ViewType.TABLE : ViewType.CARD;
		dispatch(updateViewType(newtype));
	};
	//debounce the resize event
	const handleWindowResizeDebounced = debounce(handleWindowResize, 300);

	useEffect(() => {
		window.addEventListener("resize", handleWindowResizeDebounced);
		return () => {
			window.removeEventListener("resize", handleWindowResizeDebounced);
		};
	}, []);

	if (!Sentry.getCurrentScope().getClient()) {
		Sentry.init({
			dsn: Config.sentryDSN,
			release: Config.buildVersion,
			environment: Config.environment,
			tracesSampleRate: Config.environment === "production" ? 0.1 : 1.0,
			replaysSessionSampleRate: Config.environment === "production" ? 0.01 : 0.1,
			replaysOnErrorSampleRate: 0.1,
			sendDefaultPii: true,
			transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
		});
	}

	Sentry.setUser({
		email: authContext.currentUser?.email || "notAvailable",
		username: authContext.currentUser?.firstName + " " + authContext.currentUser?.lastName,
	});

	return (
		<GraphQLProvider>
			<AppThemeProvider>
				<SnackbarProvider
					dense
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<ConfirmProvider>
						<Layout />
					</ConfirmProvider>
				</SnackbarProvider>
			</AppThemeProvider>
		</GraphQLProvider>
	);
};
export default App;
