import { useEffect, useState, useContext, useRef } from "react";
import { useLocationsQuery } from "~/services/endpoints";

//material-ui
import { Alert, Autocomplete, TextField } from "@mui/material";

import { AuthContext } from "~/auth";
import { LocationDocument, LocationType } from "~/services/graphql";
import { ClientError } from "graphql-request";

import { anyToString } from "~/helpers/toString";
import { useLogError } from "~/hooks/useLogError";

interface SelectSiteProps {
	initialValue?: LocationDocument;
	handleInputChange: (val: LocationDocument | null) => void;
}

const SelectSite = ({ initialValue, handleInputChange }: SelectSiteProps) => {
	const [sites, setSites] = useState<LocationDocument[]>([]);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [cursor, setCursor] = useState<string | null>(null);
	const authContext = useContext(AuthContext);
	const [logError] = useLogError();

	const [acVal, setAcVal] = useState<LocationDocument | null>(initialValue || null);
	const [acOpen, setAcOpen] = useState(false);

	const { data, error, isLoading, refetch } = useLocationsQuery(
		{ tenantId: authContext.company?.abbreviation || "NOTFOUND", locationTypes: [LocationType.Site], first: 50, after: cursor },
		{
			skip: !hasNextPage,
		}
	);

	useEffect(() => {
		if (data && data.locations && data.locations.nodes && data.locations.nodes.length > 0) {
			let nodes: LocationDocument[] = data.locations.nodes || [];
			setSites((prevData) => [...prevData, ...nodes] as LocationDocument[]);
			setHasNextPage(data.locations.pageInfo.hasNextPage);
			setCursor(data.locations.pageInfo.endCursor || null);
		}
	}, [data]);

	useEffect(() => {
		if (hasNextPage) {
			refetch();
		}
	}, [hasNextPage, refetch]);

	const acInput = useRef<HTMLInputElement>(null);

	const acFilter = (options: LocationDocument[], state: any) => {
		const result = options.filter((option) => `${option.name}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			setAcVal(result[0]);
			handleInputChange(result[0]);
			setAcOpen(false);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;

	if (error) {
		const clientError = error as ClientError;
		const errorMessage = `Error: ${anyToString(clientError)}`;
		logError({ message: anyToString(clientError.message), code: "SelectSiteError", detail: clientError });
		return <Alert severity="error">{errorMessage}</Alert>;
	}

	return (
		<Autocomplete
			value={acVal}
			open={acOpen}
			onOpen={() => setAcOpen(true)}
			onClose={() => setAcOpen(false)}
			//onSelect={()=> setAcOpen(false)}
			onChange={(_e, v) => {
				setAcVal(v);
				handleInputChange(v);
			}}
			filterOptions={acFilter}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			id="upc-input"
			options={sites}
			getOptionLabel={(option) => (option.name ? `${option.name}` : "")}
			style={{ width: "100%" }}
			renderInput={(params) => <TextField {...params} label="Site" variant="outlined" autoFocus={true} ref={acInput} />}
		/>
	);
};

export default SelectSite;
