import { useState } from "react";

import { Icon, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "~/hooks/reduxHooks";

function ActionMenu() {
	const navigate = useNavigate();
	const actionLinks = useAppSelector((state) => state.layout.actionLinks);

	const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);

	const handleMenuItemClick = (path: string) => {
		navigate(path);
		setActionMenuAnchorEl(null);
	};

	const handleActionMenuClose = () => {
		setActionMenuAnchorEl(null);
	};

	if (!actionLinks || actionLinks.length === 0) {
		return null;
	}

	return (
		<>
			<IconButton
				color="inherit"
				onClick={(event) => {
					setActionMenuAnchorEl(event.target as HTMLElement);
				}}
			>
				<Icon>arrow_drop_down_circle</Icon>
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={actionMenuAnchorEl}
				open={Boolean(actionMenuAnchorEl)}
				onClose={handleActionMenuClose}
				MenuListProps={{
					"aria-labelledby": "action-button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{actionLinks.map((item, i) => (
					<MenuItem sx={{ minWidth: "300px" }} key={i} onClick={() => handleMenuItemClick(item.link || "")}>
						<ListItemIcon>
							<Icon>{item.iconName}</Icon>
						</ListItemIcon>{" "}
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

export default ActionMenu;
