//material-ui
import { Grid, GridProps } from "@mui/material";

const CountBox: React.FC<GridProps & React.ComponentProps<typeof Grid>> = ({ children, ...props }) => {
	return (
		<Grid
			container
			spacing={0}
			alignItems="stretch"
			direction="row"
			sx={{
				backgroundColor: (theme) => theme.palette.background.paper,
				borderWidth: "0 0 1px 0",
				borderStyle: "solid",
				borderColor: (theme) => theme.palette.divider,
				height: "100%",
				padding: 1,
			}}
			{...props}
		>
			{children}
		</Grid>
	);
};

export default CountBox;
