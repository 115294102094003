import { useEffect } from "react";

import { Typography } from "@mui/material";

import SelectSite from "~/components/select/SelectSite";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { LocationDocument } from "~/services/graphql";
import { updateSite, updateSiteRequired } from "~/store/siteSlice";

interface RequireSiteProps {
	required?: boolean;
	children: React.ReactNode;
}

function RequireSite({ required, children }: RequireSiteProps) {
	const site = useAppSelector((state) => state.site);
	const dispatch = useAppDispatch();
	if (required === undefined) {
		required = true;
	}

	const handleInputChange = (val: LocationDocument | null) => {
		dispatch(updateSite(val));
	};

	useEffect(() => {
		dispatch(updateSiteRequired(required));
	}, [required]);

	if (!required || (site && site.location && site.location.id)) {
		return <>{children}</>;
	} else {
		return (
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Typography variant="h5" component="h5">
						Select a Site
					</Typography>
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					<SelectSite handleInputChange={handleInputChange} />
				</SpacedGridItem>
			</SpacedGridContainer>
		);
	}
}

export default RequireSite;
