import { useContext } from "react";

import { Button, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { AuthContext } from "~/auth/AuthProvider";
import SpacedGridItem from "~/components/SpacedGridItem";
import Config from "~/config";

const Account = () => {
	const authContext = useContext(AuthContext);

	const logout = async () => {
		authContext.redirectToLogout();
	};

	return (
		<Grid container alignItems="stretch" direction="row">
			{authContext.currentUser ? (
				<>
					<SpacedGridItem maxCols={3} smMargin>
						<Typography sx={{ paddingBottom: 0 }} variant="h6">
							User Info
						</Typography>
						<List>
							<ListItem>
								<ListItemIcon>
									<Icon>person</Icon>
								</ListItemIcon>
								<ListItemText>
									{authContext.currentUser.firstName} {authContext.currentUser.lastName}
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<Icon>email</Icon>
								</ListItemIcon>
								<ListItemText>{authContext.currentUser.email} </ListItemText>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<Icon>business</Icon>
								</ListItemIcon>
								<ListItemText>{authContext.company?.name} </ListItemText>
							</ListItem>
						</List>
					</SpacedGridItem>
					<SpacedGridItem maxCols={3} smMargin>
						<Typography sx={{ paddingBottom: 0 }} variant="h6">
							Api Endpoint
						</Typography>

						<List>
							<ListItem>
								<ListItemIcon>
									<Icon>api</Icon>
								</ListItemIcon>
								<ListItemText>{Config.endpoint} </ListItemText>
							</ListItem>
						</List>
					</SpacedGridItem>
				</>
			) : null}

			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">Version</Typography>
				<dl>
					<dd>Release: {Config.releaseVersion}</dd>
					<dd>Build: {Config.buildVersion}</dd>
				</dl>
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Button color="secondary" variant="contained" fullWidth={true} onClick={() => logout()}>
					Sign Out
				</Button>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<p style={{ fontSize: "smaller", opacity: 0.5 }}>{navigator.userAgent}</p>
			</SpacedGridItem>
		</Grid>
	);
};
export default Account;
