import { useContext, useState } from "react";

import { Box, Button, Collapse, Grid, Icon, TextField, Typography } from "@mui/material";
import ScreenPaper from "~count/components/ScreenPaper";
import SelectWorkorder from "~count/components/SelectWorkorder";
import { useConfirm } from "material-ui-confirm";
import { Navigate, useParams } from "react-router-dom";

import { AuthContext } from "~/auth";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime } from "~/helpers/timestampFormat";
import wsId from "~/helpers/wsId";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { useSnacks } from "~/providers/SnackProvider";
import {
	JobDocument,
	useChangeWorkOrderQuantityProducedMutation,
	useChangeWorkOrderStatusMutation,
	useSetWorkOrderLineNumberMutation,
	useWorkOrdersQuery,
	WorkOrder,
	WorkOrdersQueryVariables,
	WorkOrderStatus,
} from "~/services/graphql";
import { addQueueItem } from "~/store/queueSlice";
interface ProductionLineProps {
	jobs: JobDocument[];
}

const ProductionLine = ({ jobs }: ProductionLineProps) => {
	const confirm = useConfirm();
	const dispatch = useAppDispatch();
	const [openSnack] = useSnacks();
	const authContext = useContext(AuthContext);
	const { lineNumber } = useParams();
	const productionLineNumber = Number(lineNumber);
	const numberOfProductionLines = useAppSelector((state) => state.settings.numberOfProductionLines);

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [count, setCount] = useState("");

	const jobIds = jobs.map((job) => job.id);

	const [changeWorkOrderQuantityProducedMutation] = useChangeWorkOrderQuantityProducedMutation();
	const [changeWorkOrderStatusMutation] = useChangeWorkOrderStatusMutation();
	const [setWorkOrderLineNumberMutation] = useSetWorkOrderLineNumberMutation();

	const postCount = (workOrderId: string, j: JobDocument, w: WorkOrder) => {
		confirm({
			title: "Are you sure you want to post the current count?",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "outlined" },
		})
			.then(() => {
				let quantityProducedVariables = {
					input: {
						tenantId: authContext.company?.abbreviation || "NOTFOUND",
						id: workOrderId,
						quantityProduced: Number(count),
					},
				};
				changeWorkOrderQuantityProducedMutation(quantityProducedVariables).then((result) => {
					console.log("changeWorkOrderQuantityProducedMutation", result);
					if (result.error || !result.data || result.data.changeWorkOrderQuantityProduced?.errors) {
						openSnack("Error posting count", "error");
						return;
					}
					let quantityProducedQueueItem = {
						id: wsId(),
						groupKey: workOrderId,
						operationName: "changeWorkOrderQuantityProducedMutation",
						variables: quantityProducedVariables,
						status: "completed",
						displayData: { count: count, job: j, workOrder: w },
					};
					dispatch(addQueueItem(quantityProducedQueueItem));
					let statusChangeVariables = {
						input: {
							tenantId: authContext.company?.abbreviation || "NOTFOUND",
							id: workOrderId,
							status: WorkOrderStatus.Closed,
						},
					};

					changeWorkOrderStatusMutation(statusChangeVariables).then((result) => {
						if (result.error || !result.data || result.data.changeWorkOrderStatus?.errors) {
							openSnack("Error updating status", "error");
							return;
						}
					});
					let changeStatusQueueItem = {
						id: wsId(),
						groupKey: workOrderId,
						operationName: "changeWorkOrderStatusMutation",
						variables: statusChangeVariables,
						status: "completed",
						displayData: { count: count, job: j, workOrder: w },
					};
					dispatch(addQueueItem(changeStatusQueueItem));
				});
			})
			.catch(() => {});
	};

	const cancelCount = () => {
		confirm({
			title: "Are you sure you want to cancel the current count? Current progress will be lost.",
			confirmationText: "Cancel This Count",
			cancellationText: "Do Not Cancel",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "outlined", color: "error" },
		})
			.then(() => {
				console.log("cancelCount");
			})
			.catch(() => {});
	};

	const handleWorkOrderSelect = (workOrderId: string) => {
		setWorkOrderLineNumberMutation({
			input: {
				tenantId: authContext.company?.abbreviation || "NOTFOUND",
				id: workOrderId,
				lineNumber: productionLineNumber,
			},
		}).then((result) => {
			if (result.error || !result.data || result.data.setWorkOrderLineNumber?.errors) {
				openSnack("Error assigning Line", "error");
				return;
			}
			let statusChangeVariables = {
				input: {
					tenantId: authContext.company?.abbreviation || "NOTFOUND",
					id: workOrderId,
					status: WorkOrderStatus.InProgress,
				},
			};
			changeWorkOrderStatusMutation(statusChangeVariables).then((result) => {
				if (result.error || !result.data || result.data.changeWorkOrderStatus?.errors) {
					openSnack("Error updating status", "error");
					return;
				}
			});
		});
	};

	let workOrdersVariables: WorkOrdersQueryVariables = {
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: [WorkOrderStatus.New, WorkOrderStatus.InProgress],
		first: 50,
		jobId: jobIds,
	};

	const { data: workOrders, isLoading, error } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (isLoading) return <div>Loading work orders...</div>;

	if (error) return <div>Error: Getting WorkOrders</div>;
	//if (error) return <Alert severity="info">{error.message}</Alert>;

	//check for valid production line
	if (!productionLineNumber || productionLineNumber > numberOfProductionLines) {
		return <Navigate to="/count/lines" />;
	}

	//find if there is a workorder assigned to a line
	let workOrder = workOrders?.workOrders?.nodes?.find((wo) => wo.lineNumber === productionLineNumber);
	let job = jobs.find((job) => job.id === workOrder?.jobId);

	if (!job || !workOrder) {
		return (
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Typography variant="h5">Line {productionLineNumber} - Select Workorder</Typography>
				</SpacedGridItem>
				<SelectWorkorder handleInputChange={handleWorkOrderSelect} jobs={jobs} workOrderStatuses={[WorkOrderStatus.New, WorkOrderStatus.InProgress]} />
			</SpacedGridContainer>
		);
	}

	return (
		<>
			<Grid container spacing="1vh" alignItems="stretch">
				<Grid item xs={12}>
					<ScreenPaper>
						<Grid container>
							<Grid item xs>
								<Typography variant="h5">Line {productionLineNumber}</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h5">WO# {workOrder.workOrderNumber}</Typography>
							</Grid>
						</Grid>
					</ScreenPaper>
				</Grid>
				<Grid item xs={12}>
					<ScreenPaper sx={{ overflow: "auto" }}>
						<Grid container spacing={1}>
							<Grid item xs>
								<Typography variant="h5">
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<Box>{job.item.name || "-"}</Box>
									</Box>
								</Typography>
							</Grid>

							<Grid item>
								<Button onClick={() => setDetailsOpen(!detailsOpen)} variant="text" size="small" color="secondary">
									Details {detailsOpen ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
								</Button>
							</Grid>
						</Grid>
						<Collapse in={detailsOpen}>
							<SpacedGridContainer mt={3}>
								<SpacedGridItem>
									Job ID: <b>{job.erpId || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									Started: <b>{stringFormatDateTime(workOrder.startDate) || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									{" "}
									Created: <b>{stringFormatDateTime(workOrder.created) || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									<Button variant="contained" color="secondary" onClick={cancelCount}>
										Cancel Count
									</Button>
								</SpacedGridItem>
							</SpacedGridContainer>
						</Collapse>
					</ScreenPaper>
				</Grid>
				<Grid item xs={12}>
					<ScreenPaper>
						<Typography variant="h6">Target</Typography>
						<div className="countbox">{workOrder.containerQuantity}</div>
					</ScreenPaper>
				</Grid>
				<Grid item xs={12}>
					<ScreenPaper>
						<Grid container spacing="1vh" alignItems="stretch">
							<Grid item xs={12}>
								<TextField label="Count" type="text" fullWidth value={count} onChange={(e) => setCount(e.target.value)}></TextField>
							</Grid>
							<Grid item xs={12} textAlign="center">
								<Button onClick={() => postCount(workOrder.id, job, workOrder)} variant="contained" color="primary" fullWidth>
									Post Count
								</Button>
							</Grid>
						</Grid>
					</ScreenPaper>
				</Grid>
			</Grid>
		</>
	);
};

export default ProductionLine;
