import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { updatePageButtons } from "~/store/layoutSlice";

const pageButtonMapping: { [key: string]: PageButton[] } = {
	"/$": [],
	"/settings": [],
	"/count/clipboard": [
		{
			key: "close",
			label: "Close",
			link: "/count",
			iconName: "close",
		},
	],
	"/count/": [
		{
			key: "clipboard",
			label: "Clipboard",
			link: "/count/clipboard",
			iconName: "assignment",
		},
	],

	// Add more paths and titles as needed
};
// regex match return first match
const getPageButtons = (path: string) => {
	const match = Object.keys(pageButtonMapping).find((key) => {
		//console.log("key", key);
		return new RegExp(key).test(path);
	});
	//console.log("match", match);
	return pageButtonMapping[match || "/"];
};

const usePageButtons = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		//console.log("path", path);
		const pageButtons = getPageButtons(path);
		dispatch(updatePageButtons(pageButtons));
	}, [location, dispatch]);
};

export default usePageButtons;
