import { useContext, useEffect } from "react";

import { Paper } from "@mui/material";
import { Navigate } from "react-router-dom";

import SFGLogo from "~/assets/SF-Logo-foronwhite.svg";
import SFGLogoDark from "~/assets/SFLogoDark.svg";
import { AuthContext } from "~/auth";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch } from "~/hooks/reduxHooks";
import { updatePageButtons, updatePageTitle } from "~/store/layoutSlice";

const Home = (props: any) => {
	let lastPage = localStorage.getItem("lastPage");
	const authContext = useContext(AuthContext);

	//set page title with redux
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updatePageTitle("Inventory"));
		dispatch(updatePageButtons([]));
	}, [dispatch]);

	return (
		<>
			{lastPage ? (
				<Navigate to={`/${lastPage}`} replace={true} />
			) : (
				<>
					<SpacedGridContainer>
						<SpacedGridItem maxCols={1} smMargin>
							<Paper sx={{ fontFamily: "Montserrat", textAlign: "center", padding: "1em" }}>
								<h1>{authContext.company?.name}</h1>
								<h4>Inventory App</h4>
							</Paper>
						</SpacedGridItem>
						<SpacedGridItem maxCols={1} smMargin>
							<Paper sx={{ textAlign: "center", padding: "1em" }}>
								<img alt="SFG Logo" src={props.themeType === "dark" ? SFGLogoDark : SFGLogo} style={{ width: "80%", maxWidth: "500px" }} />
							</Paper>
						</SpacedGridItem>
					</SpacedGridContainer>
				</>
			)}
		</>
	);
};

export default Home;
