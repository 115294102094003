import { useEffect, useState, useRef } from "react";

//redux
import { useAppSelector } from "~/hooks/reduxHooks";

//material-ui
import { Autocomplete, IconButton, TextField, InputAdornment, Icon } from "@mui/material";

import useLocationsPaginatedQuery from "~/services/paginatedHooks/useLocationsPaginated";
import { LocationDocument, LocationType } from "~/services/graphql";

interface SelectLocationProps {
	locationTypes: LocationType[];
	handleInputChange: (val: LocationDocument | null) => void;
}

const SelectLocation = ({ locationTypes, handleInputChange }: SelectLocationProps) => {
	const siteLocation = useAppSelector((state) => state.site.location);
	const [levelLocations, setLevelLocations] = useState<LocationDocument[]>([]);
	const [backToLocation, setBackToLocation] = useState<LocationDocument | null>(null);
	const [locationPath, setLocationPath] = useState<string>("");
	const [locationSelected, setLocationSelected] = useState<LocationDocument | null>(null);

	const [acVal, setAcVal] = useState<LocationDocument | null>(null);
	const [acKey, setAcKey] = useState(0);
	const [acOpen, setAcOpen] = useState(false);
	const acInput = useRef<HTMLInputElement>(null);

	if (!siteLocation) {
		return <p>Error: Site not selected</p>;
	}

	const { allLocations, isLoading, isError } = useLocationsPaginatedQuery(locationTypes);

	const handleLocationChange = (l: LocationDocument | null) => {
		let children: LocationDocument[] = [];

		//if null get top level locations, direct children of siteLocation
		if (l === null) {
			children = allLocations.filter((loc) => loc.parentLocationId === siteLocation.id);
			if (backToLocation) {
				setBackToLocation(null);
			}
			//otherwise get children of current location
		} else {
			children = allLocations.filter((loc) => loc.parentLocationId === l.id);
			//get parent of val and prepend to children so user can go back
			const parent = allLocations.find((l) => l.id === l.parentLocationId) || null;
			// if there is a parent set back to location to parent and prepend to children
			if (parent) {
				setBackToLocation(parent);
				children.length > 0 && children.unshift(parent);
				//otherwise set back to location to null and prepend siteLocation to children
			} else {
				setBackToLocation(null);
				children.length > 0 && children.unshift(siteLocation);
			}
		}
		//set locationPath for display
		getLocationPath(l);
		//if no children set locationSelected and call handleInputChange from parent component
		if (children.length === 0) {
			handleInputChange(l);
			setLocationSelected(l);
			//otherwise set levelLocations to children and locationSelected to null
		} else {
			setAcKey(acKey + 1); //rerender autocomplete
			handleInputChange(null);
			setLevelLocations(children);
			setLocationSelected(null);
		}
	};

	const editLocation = () => {
		//get value for parent of locationSelected and call handleLocationChange with that value
		const parent = allLocations.find((l) => l.id === locationSelected?.parentLocationId) || null;
		handleLocationChange(parent);
		setAcOpen(true);
	};

	const getLocationPath = (loc: LocationDocument | null) => {
		if (!loc) {
			setLocationPath("");
			return;
		}
		let path = loc.name;
		let parent = allLocations.find((l) => l.id === loc.parentLocationId);
		while (parent && parent !== undefined) {
			path = `${parent.name} > ${path}`;
			let thisParentId = parent.parentLocationId;
			parent = allLocations.find((l) => l.id === thisParentId);
		}
		setLocationPath(path);
	};

	useEffect(() => {
		handleLocationChange(null);
	}, [siteLocation, allLocations]);

	const acFilter = (options: LocationDocument[], state: any) => {
		const result = options.filter((option) => `${option.name}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			handleLocationChange(result[0]);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;

	if (isError) {
		return <p>Error: getting Locations</p>;
	}

	if (locationSelected) {
		return (
			<TextField
				label="Location"
				value={locationPath}
				variant="outlined"
				disabled
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={editLocation}>
								<Icon>edit</Icon>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		);
	}

	return (
		<>
			<Autocomplete
				value={acVal}
				key={acKey}
				open={acOpen || locationPath !== ""}
				onOpen={() => setAcOpen(true)}
				onBlur={() => {
					if (!locationSelected) {
						handleLocationChange(null);
						setAcOpen(false);
					}
				}}
				//onSelect={()=> setAcOpen(false)}
				onChange={(_e, v) => {
					setAcVal(null);
					if (v?.id === siteLocation.id) {
						handleLocationChange(null);
						setAcOpen(true);
					} else {
						handleLocationChange(v);
					}
				}}
				filterOptions={acFilter}
				//isOptionEqualToValue={(option, value) => option.name === value.name}
				id="location-input"
				options={levelLocations}
				getOptionLabel={(option) => {
					if (option.id === backToLocation?.id || option.id === siteLocation.id) {
						return `< Back`;
					} else {
						return `${option.name}`;
					}
				}}
				style={{ width: "100%" }}
				renderInput={(params) => <TextField {...params} label={locationPath || "Location"} variant="outlined" ref={acInput} />}
			/>
		</>
	);
};

export default SelectLocation;
