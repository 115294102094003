import { useState } from "react";

import { Icon, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { setErrorsViewed } from "~/store/errorSlice";

function ErrorMenu() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [errorMenuAnchorEl, setErrorMenuAnchorEl] = useState<null | HTMLElement>(null);

	const newErrors = useAppSelector((state) => state.error.newErrors);

	const handleViewErrors = () => {
		setErrorMenuAnchorEl(null);
		navigate("/errorLog");
	};

	const handleDismissErrors = () => {
		setErrorMenuAnchorEl(null);
		dispatch(setErrorsViewed());
	};

	const handleErrorMenuClose = () => {
		setErrorMenuAnchorEl(null);
	};

	if (!newErrors) {
		return null;
	}

	return (
		<>
			<IconButton
				onClick={(event) => {
					setErrorMenuAnchorEl(event.target as HTMLElement);
				}}
			>
				<Icon color="warning">error</Icon>
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={errorMenuAnchorEl}
				open={Boolean(errorMenuAnchorEl)}
				onClose={handleErrorMenuClose}
				MenuListProps={{
					"aria-labelledby": "action-button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem sx={{ minWidth: "300px" }} key={0} onClick={() => handleViewErrors()}>
					<ListItemIcon>
						<Icon>visibility</Icon>
					</ListItemIcon>{" "}
					View {newErrors} new errors
				</MenuItem>
				<MenuItem sx={{ minWidth: "300px" }} key={1} onClick={() => handleDismissErrors()}>
					<ListItemIcon>
						<Icon>clear_all</Icon>
					</ListItemIcon>{" "}
					Dismiss errors
				</MenuItem>
			</Menu>
		</>
	);
}

export default ErrorMenu;
