import { useEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";

import RequireAuth from "~/auth/RequireAuth";
import RequireSite from "~/components/RequireSite";
import { useAppDispatch } from "~/hooks/reduxHooks";
import Account from "~/pages/Account";
import Count from "~/pages/Count";
import ErrorLog from "~/pages/ErrorLog";
import Forbidden from "~/pages/Forbidden";
import Home from "~/pages/Home";
import Items from "~/pages/items";
import OAuthRedirect from "~/pages/OAuthRedirect";
import Settings from "~/pages/Settings";
import { hideTopBar, showTopBar } from "~/store/layoutSlice";

const Router = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (location.pathname.startsWith("/count/counting/")) {
			dispatch(hideTopBar());
		} else {
			dispatch(showTopBar());
		}
	}, [location, dispatch]);
	return (
		<Routes>
			<Route path="/oauth-redirect" element={<OAuthRedirect />} />
			<Route path="/Forbidden" element={<Forbidden />} />
			<Route path="/errorLog" element={<ErrorLog />} />
			<Route
				path="/settings"
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Settings />
						</RequireSite>
					</RequireAuth>
				}
			/>
			<Route
				path="/account"
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Account />
						</RequireSite>
					</RequireAuth>
				}
			/>
			<Route
				path="/items"
				element={
					<RequireAuth>
						<RequireSite>
							<Items />
						</RequireSite>
					</RequireAuth>
				}
			/>
			<Route
				path="/count/*"
				element={
					<RequireAuth>
						<RequireSite>
							<Count />
						</RequireSite>
					</RequireAuth>
				}
			/>
			<Route
				path="/"
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Home />
						</RequireSite>
					</RequireAuth>
				}
			/>

			<Route path="*" element={<p>Path not resolved</p>} />
		</Routes>
	);
};

export default Router;
