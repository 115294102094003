import { useEffect, useState } from "react";

import { Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";

import ModalDialog from "~/components/ModalDialog";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { timestampFormatDateTime } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { ErrorDetail, setErrorsViewed } from "~/store/errorSlice";

const ErrorLog = () => {
	const dispatch = useAppDispatch();
	const errors = useAppSelector((state) => state.error.errors);

	const [selectedError, setSelectedError] = useState<ErrorDetail | null>(null);

	useEffect(() => {
		dispatch(setErrorsViewed());
	}, [dispatch]);

	if (!errors.length) {
		return <SpacedGridItem>No Errors to show</SpacedGridItem>;
	}

	return (
		<>
			<SpacedGridContainer>
				{errors.map((v, i) => {
					return (
						<SpacedGridItem key={i} smMargin>
							<Card style={{ width: "100%", height: "100%	" }}>
								<CardContent>
									<Typography gutterBottom>{timestampFormatDateTime(Number(v.timestamp))}</Typography>

									{v.code && (
										<Typography variant="h6" component="div">
											{v.code}
										</Typography>
									)}
									{v.message.length > 200 ? (
										<Typography variant="body2" color="textSecondary" component="div">
											{v.message.substring(0, 200) + "..."}
										</Typography>
									) : (
										<Typography variant="body2" color="textSecondary" component="div">
											{v.message}
										</Typography>
									)}
								</CardContent>
								<CardActions>
									<Button size="small" color="secondary" variant="contained" onClick={() => setSelectedError(v)}>
										Show Details
									</Button>
								</CardActions>
							</Card>
						</SpacedGridItem>
					);
				})}
			</SpacedGridContainer>

			<ModalDialog
				open={selectedError !== null}
				close={() => {
					setSelectedError(null);
				}}
				title="Error Details"
				showCloseButton
			>
				<pre style={{ whiteSpace: "pre-wrap" }}>
					<Typography paragraph variant="h6">
						Message
					</Typography>
					<Typography paragraph variant="body1">
						{selectedError?.message || "--"}
					</Typography>
					<Divider sx={{ marginBottom: 3 }} />
					{selectedError?.code && (
						<>
							<Typography paragraph variant="h6">
								Code
							</Typography>
							<Typography paragraph variant="body1">
								{selectedError?.code || "--"}
							</Typography>
							<Divider sx={{ marginBottom: 3 }} />
						</>
					)}
					<Typography paragraph variant="h6">
						Detail
					</Typography>
					<Typography paragraph variant="body1">
						{String(selectedError?.detail) === "[object Object]" ? JSON.stringify(selectedError?.detail, null, 1) : String(selectedError?.detail)}
					</Typography>
					<Divider sx={{ marginBottom: 3 }} />
					{selectedError?.extra && (
						<>
							<Typography paragraph variant="h6">
								Extra
							</Typography>
							<Typography paragraph variant="body1">
								{String(selectedError.extra) === "[object Object]" ? JSON.stringify(selectedError.extra, null, 1) : String(selectedError.extra)}
							</Typography>
						</>
					)}
				</pre>
			</ModalDialog>
		</>
	);
};
export default ErrorLog;
