import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

import { getFromIndexedDB, setToIndexedDB } from "~/db";
import client from "~/services/client";

//const CACHE_DURATION = 5 * 60 * 1000; // Cache duration in milliseconds (5 minutes)
//TODO: FOR TESTING SET BACK FOR PRODUCTION
//const CACHE_DURATION = 20 * 1000; // Cache duration in milliseconds (20 seconds)

interface CachedData<T> {
	data: T;
	timestamp: number;
}

const graphqlBaseQuery = graphqlRequestBaseQuery({
	client,
});

interface GraphQLRequestArgs {
	document: string;
	variables?: Record<string, any>;
}

export const baseQueryGraphql: BaseQueryFn<GraphQLRequestArgs, unknown, unknown> = async ({ document, variables }, api, extraOptions) => {
	let result;
	//const queryKey = api.endpoint + "-" + String(variables?.first) + "-" + String(variables?.after);
	const queryKey = JSON.stringify({ document, variables });

	// Check if data is in IndexedDB cache, use if not expired
	const cachedData = await getFromIndexedDB<CachedData<unknown>>("apiCache", queryKey);
	//if offline and there is cached data, return it
	if (!navigator.onLine && cachedData) {
		//console.log("offline and cached data");
		return { data: cachedData.data };
	}
	//if online and there is cached data, check if it is expired
	/*if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
		console.log("online and cached data not expired");
		return { data: cachedData.data };
	}*/
	try {
		//console.log("online and cached data expired || offline and no cached data");
		result = await graphqlBaseQuery({ document, variables }, api, extraOptions);
		if (result.error) {
			//if there is cached data, return it
			if (cachedData) {
				return { data: cachedData.data };
			} else {
				return { error: result.error };
			}
		}
		// Save data to IndexedDB cache
		if (result.data) {
			setToIndexedDB("apiCache", queryKey, { data: result.data, timestamp: Date.now() });
		}

		return result;
	} catch (e: any) {
		console.error("Error in baseQueryGraphql", e);
		if (cachedData) {
			return { data: cachedData.data };
		} else {
			return { error: e };
		}
	}
};

export const baseApiWithGraphql = createApi({
	baseQuery: baseQueryGraphql,
	endpoints: () => ({}),
	tagTypes: [],
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
});
