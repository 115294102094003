import { useContext, useEffect } from "react";

import { LinearProgress } from "@mui/material";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "~/auth";

function OAuthRedirect() {
	const { search } = useLocation();

	const navigate = useNavigate();

	const oauth2: OAuth2Context = useContext(AuthContext);

	useEffect(() => {
		async function handleCodeExchange() {
			// get code parameter from querystring
			const values = queryString.parse(search);
			const code = String(values.code);

			if (!code) {
				throw new Error("Invalid OAuth redirect code.");
			}

			await oauth2.performCodeExchange(code);

			// Use passed through returnTo state value to determine where to redirect now
			const authStateStr = String(values.state);
			let returnTo = "/";
			if (authStateStr) {
				const authState = JSON.parse(authStateStr!);
				returnTo = authState.returnTo;
			}
			navigate(returnTo);
		}
		handleCodeExchange();
	}, [history, oauth2, search]);

	return <LinearProgress />;
}

export default OAuthRedirect;
