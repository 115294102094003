import { Grid, Icon, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";

import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { timestampFormatDayTime } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { removeQueueItem } from "~/store/queueSlice";

const SetDownsCurrent = () => {
	const { workOrderID } = useParams();
	const dispatch = useAppDispatch();
	const queueItems = useAppSelector((state) =>
		state.queue.queueItems.filter((item) => item.operationName === "SetDown" && item.groupKey === (workOrderID || ""))
	);
	const viewType = useAppSelector((state) => state.layout.viewType);

	console.log("queueItems", queueItems);

	if (!queueItems.length) {
		<Typography variant="h6">Set Down Locations</Typography>;

		return <div>No SetDowns</div>;
	}

	if (viewType === "table") {
		const columns: GridColDef[] = [
			{ field: "id", headerName: "id", flex: 1 },
			{ field: "addedTimestamp", headerName: "Date", flex: 1, valueFormatter: (value?: string) => timestampFormatDayTime(Number(value)) },
			{ field: "location", headerName: "Location", flex: 1, valueGetter: (_value, row) => row.displayData?.location || "" },
			{ field: "count", headerName: "Count", flex: 1, valueGetter: (_value, row) => row.displayData?.count || "" },
			{ field: "status", headerName: "Status", flex: 1 },
		];

		const columnVisibility: GridColumnVisibilityModel = {
			id: false,
			Date: true,
			Location: true,
			Count: true,
			Status: true,
		};

		return (
			<SpacedGridItem maxCols={1}>
				<Typography variant="h6">Locations</Typography>
				<div style={{ width: "100%" }}>
					<DataGrid
						rows={queueItems}
						columns={columns}
						autoHeight={true}
						columnVisibilityModel={columnVisibility}
						disableColumnFilter={true}
						disableColumnMenu={true}
						hideFooter={true}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<Typography variant="h6">Locations</Typography>
			<SpacedGridContainer>
				{queueItems.map((qi, i) => {
					if (!qi) return null;
					return (
						<SpacedGridItem key={i}>
							<SelectCard
								onClick={() => {
									return;
								}}
							>
								<Grid container spacing={1} alignItems="stretch" direction="row">
									<Grid item xs>
										<Typography variant="inherit" component="span" color="textPrimary">
											{qi.displayData?.location}
										</Typography>
									</Grid>

									{qi.status === "pending" ? (
										<Grid item textAlign="end">
											<IconButton size="small" onClick={() => dispatch(removeQueueItem(qi))} style={{ marginRight: 10 }}>
												<Icon>delete</Icon>
											</IconButton>

											<IconButton size="small" color="secondary" onClick={() => void 0}>
												<Icon>edit</Icon>
											</IconButton>
										</Grid>
									) : null}
									<Grid item xs={12}>
										<Typography variant="inherit" component="span" color="textSecondary">
											Quantity: {qi.displayData?.count}
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<Typography variant="inherit" component="span" color="textSecondary">
											Status: {qi.status}
										</Typography>
									</Grid>
									<Grid item xs={6} style={{ textAlign: "end" }}>
										<Typography variant="inherit" component="span" color="textSecondary">
											{timestampFormatDayTime(Number(qi.addedTimestamp))}
										</Typography>
									</Grid>
								</Grid>
							</SelectCard>
						</SpacedGridItem>
					);
				})}
			</SpacedGridContainer>
		</>
	);
};

export default SetDownsCurrent;
