import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobDocument, WorkOrder } from "~/services/graphql";

interface WorkOrderExtended {
	WorkOrderId: string;
}

export interface ProductionLineQueueItem {
	id: string;
	job: JobDocument;
	workOrder: WorkOrder;
	count: number;
	addedTimestamp: number;
	updatedTimestamp: number;
	status: string;
}

interface ProductionLineState {
	lineNumber: number;
	job?: JobDocument | undefined;
	workOrder?: WorkOrder | undefined;
	workOrdersExtended?: WorkOrderExtended[] | undefined;
	queue: ProductionLineQueueItem[];
}

const initialState: ProductionLineState = {
	lineNumber: 1,
	queue: [],
};

export const productionLineSlice = createSlice({
	name: "productionLine",
	initialState,
	reducers: {
		updateProductionLineJob: (state, action: PayloadAction<{ job: JobDocument | null; workOrder: WorkOrder | null }>) => {
			if (!action.payload || action.payload.job === null || action.payload.workOrder === null) {
				state.job === undefined;
				state.workOrder === undefined;
			} else {
				if (!state.job) {
					state.job = action.payload.job;
				} else {
					Object.assign(state.job, action.payload.job);
				}
				if (!state.workOrder) {
					state.workOrder = action.payload.workOrder;
				} else {
					Object.assign(state.workOrder, action.payload.workOrder);
				}
			}
		},
		updateWorkordersExtended: (state, action: PayloadAction<WorkOrderExtended | null>) => {
			if (!action.payload) {
				state.workOrdersExtended === undefined;
			} else {
				if (!state.workOrdersExtended) {
					if (state.workOrdersExtended === undefined) state.workOrdersExtended = [];
					state.workOrdersExtended.push(action.payload);
				} else {
					//find matching workorderextended by WorkOrderId and update
					let match = state.workOrdersExtended.find((wo) => wo.WorkOrderId === action.payload?.WorkOrderId);
					//update match in state

					if (match) {
						Object.assign(match, action.payload);
					} else {
						state.workOrdersExtended.push(action.payload);
					}

					Object.assign(state.workOrdersExtended, action.payload);
				}
			}
		},
		updateProductionLine: (state, action: PayloadAction<number>) => {
			state.lineNumber = action.payload || initialState.lineNumber;
		},
		addProductionCountQueue: (state, action: PayloadAction<ProductionLineQueueItem>) => {
			let q = [...state.queue, action.payload];
			q.sort((a, b) => {
				if (a.addedTimestamp === b.addedTimestamp) return 0;
				return a.addedTimestamp < b.addedTimestamp ? 1 : -1;
			});
			state.queue = q;
			delete state.job;
			delete state.workOrder;
			delete state.workOrdersExtended;
		},
		updateProductionCountQueue: (state, action: PayloadAction<ProductionLineQueueItem>) => {
			//update the countMultiple of the item in the queue with the same id then update state
			let q = state.queue;
			q = q.map((item) => {
				if (item.id === action.payload.id) {
					return action.payload;
				}
				return item;
			});
			q.sort((a, b) => {
				if (a.addedTimestamp === b.addedTimestamp) return 0;
				return a.addedTimestamp < b.addedTimestamp ? 1 : -1;
			});
			console.log("q", q);
			state.queue = { ...q };
		},
		removeProductionCountQueue: (state, action: PayloadAction<ProductionLineQueueItem>) => {
			//remove the item from the queue with the same id then update state
			const queue = state.queue.filter((item) => {
				return item.id !== action.payload.id;
			});
			state.queue = { ...queue };
		},
	},
});

export const {
	updateProductionLineJob,
	updateProductionLine,
	updateWorkordersExtended,
	addProductionCountQueue,
	updateProductionCountQueue,
	removeProductionCountQueue,
} = productionLineSlice.actions;

export default productionLineSlice.reducer;
