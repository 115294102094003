import { useContext } from "react";

import CountClipboard from "~count/CountClipboard";
import CountMenu from "~count/CountMenu";
import ProductionLine from "~count/ProductionLine";
import ProductionLines from "~count/ProductionLines";
import SetDown from "~count/SetDown";
import { Route, Routes } from "react-router-dom";

import { AuthContext } from "~/auth";
import { useAppSelector } from "~/hooks/reduxHooks";
import { JobsQueryVariables, JobStatus, useJobsQuery } from "~/services/graphql";

const Count = () => {
	const authContext = useContext(AuthContext);
	const site = useAppSelector((state) => state.site);

	//const { allJobs, isLoading, isError } = useJobsPaginatedQuery();
	let jobsVariables: JobsQueryVariables = {
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		jobStatus: [JobStatus.Released, JobStatus.InProduction],
		first: 50,
		locationId: site.location?.id || "NOTFOUND",
	};
	const { data, isLoading, error } = useJobsQuery(jobsVariables, { pollingInterval: 30000 });
	// If jobs are loading or there's an error, handle that state
	if (isLoading) return <div>Loading jobs...</div>;
	if (error) return <div>Error: Getting Jobs</div>;

	return (
		<>
			<Routes>
				<Route path="" element={<CountMenu />} />
				<Route path="clipboard" element={<CountClipboard />} />
				<Route path="line/:lineNumber" element={<ProductionLine jobs={data?.jobs?.nodes || []} />} />
				<Route path="lines" element={<ProductionLines jobs={data?.jobs?.nodes || []} />} />
				<Route path="setdown/:workOrderID?" element={<SetDown jobs={data?.jobs?.nodes || []} />} />
			</Routes>
		</>
	);
};

export default Count;
