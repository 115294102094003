import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { updatePageTitle } from "~/store/layoutSlice";

const pageTitleMapping: { [key: string]: string } = {
	"/$": "Home",
	"/settings$": "Settings",
	"/account$": "Account",
	"/count$": "Production",
	"/count/counting": "Production Counting",
	"/count/setdown": "Set Down",
	"/count/line/": "Production Line",
	"/count/lines": "Production Lines",
	"/count/clipboard": "Production Clipboard",
	"/count": "Count (no title set)",
	"/errorLog": "Error Log",
	"/items": "Items",
	// Add more paths and titles as needed
};
// regex match return first match
const getPageTitle = (path: string) => {
	const match = Object.keys(pageTitleMapping).find((key) => {
		//console.log("key", key);
		return new RegExp(key).test(path);
	});
	//console.log("match", match);
	return pageTitleMapping[match || "/"];
};

const usePageTitle = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		//console.log("path", path);
		const pageTitle = getPageTitle(path);
		dispatch(updatePageTitle(pageTitle));
		document.title = pageTitle;
	}, [location, dispatch]);
};

export default usePageTitle;
