import { useEffect, useState, useContext, useRef } from "react";
import { useItemsQuery } from "~/services/endpoints";

//ui
import { Autocomplete, TextField } from "@mui/material";

import { AuthContext } from "~/auth";
import { ItemSummaryDocument } from "~/services/graphql";
import { ClientError } from "graphql-request";

const SelectItem = () => {
	const [allData, setAllData] = useState<ItemSummaryDocument[]>([]);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [cursor, setCursor] = useState<string | null>(null);
	const authContext = useContext(AuthContext);

	const [acVal, setAcVal] = useState<ItemSummaryDocument | null>(null);
	const [acOpen, setAcOpen] = useState(false);

	const { data, error, isLoading, refetch } = useItemsQuery(
		{ tenantId: authContext.company?.abbreviation || "NOTFOUND", first: 1000, after: cursor },
		{
			skip: !hasNextPage,
		}
	);

	useEffect(() => {
		if (data && data.items && data.items.nodes && data.items.nodes.length > 0) {
			let nodes = data.items.nodes || [];
			setAllData((prevData) => [...prevData, ...nodes]);
			setHasNextPage(data.items.pageInfo.hasNextPage);
			setCursor(data.items.pageInfo.endCursor || null);
		}
	}, [data]);

	useEffect(() => {
		if (hasNextPage) {
			refetch();
		}
	}, [hasNextPage, refetch]);

	const handleInputChange = (val: any) => {
		console.log(val);
		//dispatch(updateSite(val));
	};

	const acInput = useRef<HTMLInputElement>(null);

	const acFilter = (options: ItemSummaryDocument[], state: any) => {
		const result = options.filter((option) => `${option.name}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			setAcVal(result[0]);
			handleInputChange(result[0]);
			setAcOpen(false);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;
	if (error) {
		const clientError = error as ClientError;
		return <p>Error: {clientError.message}</p>;
	}

	return (
		<Autocomplete
			value={acVal}
			open={acOpen}
			onOpen={() => setAcOpen(true)}
			onClose={() => setAcOpen(false)}
			//onSelect={()=> setAcOpen(false)}
			onChange={(_e, v) => {
				setAcVal(v);
				handleInputChange(v);
			}}
			filterOptions={acFilter}
			isOptionEqualToValue={(option, value) => option.name === value.name}
			id="upc-input"
			options={allData}
			getOptionLabel={(option) => (option.name ? `${option.name}` : "")}
			style={{ width: "100%" }}
			renderInput={(params) => <TextField {...params} label="Item" variant="outlined" autoFocus={true} ref={acInput} />}
		/>
	);
};

export default SelectItem;
