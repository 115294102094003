import {
	ChangeWorkOrderQuantityProducedMutation,
	ChangeWorkOrderStatusMutation,
	api as generatedApi,
	ItemsQuery,
	JobQuery,
	JobsQuery,
	LocationsQuery,
	SetWorkOrderLineNumberMutation,
	WorkOrdersQuery,
} from "~/services/graphql";

export const apiEndpoints = generatedApi.enhanceEndpoints({
	addTagTypes: ["Items", "Locations", "Job", "WorkOrder"],
	endpoints: {
		items: {
			providesTags: ["Items"],
			transformResponse: (res: ItemsQuery) => {
				return res;
			},
		},
		locations: {
			providesTags: ["Locations"],
			transformResponse: (res: LocationsQuery) => {
				return res;
			},
		},
		job: {
			providesTags: ["Job"],
			transformResponse: (res: JobQuery) => {
				return res;
			},
		},
		jobs: {
			providesTags: ["Job"],
			transformResponse: (res: JobsQuery) => {
				return res;
			},
		},
		workOrders: {
			providesTags: ["WorkOrder"],
			transformResponse: (res: WorkOrdersQuery) => {
				return res;
			},
		},
		changeWorkOrderQuantityProduced: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: ChangeWorkOrderQuantityProducedMutation) => {
				return res;
			},
		},
		changeWorkOrderStatus: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: ChangeWorkOrderStatusMutation) => {
				return res;
			},
		},
		setWorkOrderLineNumber: {
			invalidatesTags: ["WorkOrder"],
			transformResponse: (res: SetWorkOrderLineNumberMutation) => {
				return res;
			},
		},
	},
});

export const {
	useItemsQuery,
	useLocationsQuery,
	useLazyLocationsQuery,
	useJobQuery,
	useLazyJobQuery,
	useJobsQuery,
	useLazyJobsQuery,
	useWorkOrdersQuery,
	useChangeWorkOrderQuantityProducedMutation,
	useChangeWorkOrderStatusMutation,
} = apiEndpoints;
