import { Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { themeOverrides, themeOverridesDark } from "~/themes/themeOverrides";

var fontSize = 14;

export function themeCreator(theme: string, type: string, defaultFontSize?: string | null): Theme {
	fontSize = Number(defaultFontSize) || fontSize;

	const mainTheme = createTheme({
		typography: {
			fontSize: fontSize,
		},
		palette: {
			primary: {
				main: "#335936" /*"#69936C"*/,
			},
			secondary: {
				main: "#8C5522",
			},
			background: {
				default: "#f5f5f5",
			},
			text: {
				primary: "#555555",
				secondary: "#777777",
			},
		},
		components: themeOverrides,
	});

	const mainThemeDark = createTheme({
		typography: {
			fontSize: fontSize,
		},
		palette: {
			mode: "dark",
			primary: {
				main: "#538E58",
			},
			secondary: {
				main: "#999999",
			},
			background: {
				default: "#222222",
			},
			text: {
				primary: "#dddddd",
				secondary: "#aaaaaa",
			},
		},
		components: themeOverridesDark,
	});

	const themeMap: any = {
		main: mainTheme,
	};

	const themeMapDark: any = {
		main: mainThemeDark,
	};

	if (type === "dark") {
		if (themeMapDark[theme]) {
			return { ...themeMapDark[theme] };
		} else {
			return { ...themeMapDark["main"] };
		}
	}
	if (themeMap[theme]) {
		return { ...themeMap[theme] };
	} else {
		return { ...themeMap["main"] };
	}
}
