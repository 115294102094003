import { Paper, PaperProps } from "@mui/material";
import { styled } from "@mui/system";

import "@fontsource/roboto-mono/200.css";

const ScreenPaper = styled(Paper)<PaperProps>(({ theme, sx }) => ({
	padding: "1vh",
	/*marginTop: "1vh",*/
	textAlign: "left",
	//color: theme.palette.text.secondary,
	/*height: "calc(30vh - 15px)",
	overflow: "auto",
	boxShadow: "0px 0px 1px 1px rgba(90,90,90,0.1)",
	minHeight: "200px",*/
	boxShadow: "unset",
	sx: sx,

	"& .underline": {
		textDecoration: "underline",
	},
	"& h3": {
		padding: 0,
		fontSize: "max(4vh, 14px)",
		lineHeight: "max(4vh, 14px)",
	},
	"& h5": {
		padding: 0,
		fontSize: "max(3vh, 14px)",
		lineHeight: "max(4vh, 14px)",
	},
	"& h6": {
		padding: 0,
		fontSize: "max(3vh, 14px)",
		lineHeight: "max(4vh, 14px)",
	},
	"& td": {
		fontSize: "2.5vmin",
	},
	"& th": {},

	"& .MuiButton-containedPrimary": {
		/*padding: ".5vh",
		minHeight: "60px",
		maxHeight: "150px",
		maxWidth: "400px",
		fontSize: "calc(16px + 2vh)",
		*/
	},
	"& .employeeBtn": {
		textTransform: "none",
		padding: ".2vh",
		/*height: "calc(30vh - 160px)",*/
		maxHeight: "80px",
		maxWidth: "200px",
		lineHeight: "calc(16px + 3vh)",
		fontSize: "calc(16px + 3vh)",
	},
	"& .employeeBtn:disabled": {
		color: "inherit",
		maxWidth: "400px",
	},

	"& div.countbox": {
		borderWidth: 0,
		borderStyle: "solid",
		borderColor: theme.palette.text.secondary,
		padding: "0",
		fontWeight: "bold",
		fontSize: "20vmin",
		lineHeight: "20vmin",
		fontFamily: "Roboto Mono, monospace",
		/*,
		fontFamily: "'Bai Jamjuree', sans-serif",*/
		textAlign: "right",
	},
	"& .overcount": {
		color: theme.palette.warning.main,
	},
	"& .endOfTime": {
		color: theme.palette.error.main,
	},
	"& div.saved": {
		textAlign: "right",
	},
	"& div.MuiChip-filled": {
		margin: "-5px",
		fontSize: "2.5vmin",
	},
	"& g.recharts-cartesian-axis-tick": {
		fontSize: "small",
	},
}));

export default ScreenPaper;
