import { useEffect, useState } from "react";

import { Divider, Drawer, DrawerProps, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Icon from "@mui/material/Icon";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { closeDrawer } from "~/store/layoutSlice";
import { updateSite } from "~/store/siteSlice";

export const drawerWidth = "100%";
export const drawerWidthMax = 360;

const MainNavDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
	display: "flex",
	width: drawerWidth,
	maxWidth: drawerWidthMax,
	flexShrink: 0,
	"& .MuiDrawer-paper": {
		width: drawerWidth,
		maxWidth: drawerWidthMax,
	},
	"& .MUIDrawer-header": {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		//...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	"& a": {
		textDecoration: "none",
		color: "inherit",
	},
}));

function NavDrawer() {
	const dispatch = useAppDispatch();
	//location
	const location = useLocation();

	const [fullscreen, setFullscreen] = useState(false);

	const drawerOpen = useAppSelector((state) => state.layout.drawerOpen);
	const currentSite = useAppSelector((state) => state.site.location);
	//navigation menu
	var navMenu = [
		{
			name: "Home",
			path: "/",
			icon: "home",
		},
	];

	useEffect(() => {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen;
		var cancelFullScreen = doc.exitFullscreen;

		if (fullscreen) {
			requestFullScreen.call(docEl);
		} else {
			cancelFullScreen.call(doc);
		}
	}, [fullscreen]);

	return (
		<MainNavDrawer variant="persistent" anchor="left" open={drawerOpen}>
			<div>
				<IconButton onClick={() => dispatch(closeDrawer())} size="large">
					{drawerOpen ? <Icon>chevron_left</Icon> : <Icon>chevron_right</Icon>}
				</IconButton>
			</div>

			<Divider />

			<List>
				{navMenu.map(function (item, index) {
					return (
						<Link to={item.path} key={index} onClick={() => dispatch(closeDrawer())}>
							<ListItem key={index}>
								<ListItemIcon>
									<Icon>{item.icon}</Icon>
								</ListItemIcon>

								<ListItemText primary={item.name} />
							</ListItem>
						</Link>
					);
				})}
				<Link to={"/count"} onClick={() => dispatch(closeDrawer())}>
					<ListItem>
						<ListItemIcon>
							<Icon>conveyor_belt</Icon>
						</ListItemIcon>
						<ListItemText primary="Production" />
					</ListItem>
				</Link>
				<Link to={"/items"} onClick={() => dispatch(closeDrawer())}>
					<ListItem>
						<ListItemIcon>
							<Icon>category</Icon>
						</ListItemIcon>
						<ListItemText primary="Items" />
					</ListItem>
				</Link>
			</List>
			<Divider />
			<List>
				<Link to={"/account"} onClick={() => dispatch(closeDrawer())}>
					<ListItem>
						<ListItemIcon>
							<Icon>account_circle</Icon>
						</ListItemIcon>
						<ListItemText primary="Account" />
					</ListItem>
				</Link>
				<Link to={"/settings"} onClick={() => dispatch(closeDrawer())}>
					<ListItem>
						<ListItemIcon>
							<Icon>settings</Icon>
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</ListItem>
				</Link>
				{location && location.pathname !== "/settings" && (
					<Link
						to={"#"}
						onClick={() => {
							dispatch(updateSite(null));
							dispatch(closeDrawer());
						}}
					>
						<ListItem>
							<ListItemIcon>
								<Icon>location_on</Icon>
							</ListItemIcon>
							<ListItemText primary={currentSite ? currentSite.name : "Select Site"} />
						</ListItem>
					</Link>
				)}
				<Link to={"/errorLog"} onClick={() => dispatch(closeDrawer())}>
					<ListItem>
						<ListItemIcon>
							<Icon>error</Icon>
						</ListItemIcon>
						<ListItemText primary="Error Log" />
					</ListItem>
				</Link>

				<ListItem
					key="fullscreeen"
					onClick={() => {
						setFullscreen(!fullscreen);
						dispatch(closeDrawer());
					}}
				>
					<ListItemIcon>
						<Icon>{fullscreen ? "fullscreen_exit" : "fullscreen"}</Icon>
					</ListItemIcon>
					<ListItemText primary={fullscreen ? "Exit full screen" : "Full screen"} />
				</ListItem>
			</List>
		</MainNavDrawer>
	);
}

export default NavDrawer;
