import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ViewType {
	TABLE = "table",
	CARD = "card",
}

export interface LayoutState {
	pageButtons: PageButton[];
	actionLinks: PageButton[];
	pageTitle: string | JSX.Element;
	internetStatus: number;
	drawerOpen: boolean;
	updateAvailable: boolean;
	showTopBar: boolean;
	viewType: ViewType;
}

const initialState: LayoutState = {
	pageButtons: [],
	actionLinks: [],
	pageTitle: "Inventory",
	internetStatus: 0,
	drawerOpen: false,
	updateAvailable: false,
	showTopBar: true,
	viewType: window.innerWidth > 1000 ? ViewType.TABLE : ViewType.CARD,
};

export const layoutSlice = createSlice({
	name: "layout",
	initialState,
	reducers: {
		hideTopBar: (state) => {
			state.showTopBar = false;
		},
		showTopBar: (state) => {
			state.showTopBar = true;
		},
		openDrawer: (state) => {
			state.drawerOpen = true;
		},
		closeDrawer: (state) => {
			state.drawerOpen = false;
		},
		appUpdateAvailable: (state) => {
			state.updateAvailable = true;
		},
		appUpdateNotAvailable: (state) => {
			state.updateAvailable = false;
		},
		updatePageTitle: (state, action: PayloadAction<string | JSX.Element>) => {
			state.pageTitle = action.payload;
		},
		updateActionLinks: (state, action: PayloadAction<PageButton[]>) => {
			state.actionLinks = action.payload;
		},
		updatePageButtons: (state, action: PayloadAction<PageButton[]>) => {
			state.pageButtons = action.payload;
		},
		updateInternetStatus: (state, action: PayloadAction<number>) => {
			state.internetStatus = action.payload;
		},
		updateViewType: (state, action: PayloadAction<ViewType>) => {
			state.viewType = action.payload;
		},
	},
});

export const {
	hideTopBar,
	showTopBar,
	updateActionLinks,
	updatePageButtons,
	updateInternetStatus,
	updatePageTitle,
	updateViewType,
	appUpdateAvailable,
	appUpdateNotAvailable,
	openDrawer,
	closeDrawer,
} = layoutSlice.actions;

export default layoutSlice.reducer;
