import { deleteDB, openDB } from "idb";

import Config from "~/config";

const dbPromise = openDB("inventory", Config.idbVersion, {
	upgrade(db) {
		db.createObjectStore("apiCache");
	},
});

export const deleteDatabase = async (): Promise<void> => {
	await deleteDB("inventory");
};

export const getFromIndexedDB = async <T>(storeName: string, key: string): Promise<T | undefined> => {
	const db = await dbPromise;
	return db.get(storeName, key);
};

export const getAllFromIndexedDB = async <T>(storeName: string): Promise<T[]> => {
	const db = await dbPromise;
	return db.getAll(storeName);
};

export const setToIndexedDB = async <T>(storeName: string, key: string, value: T): Promise<void> => {
	const db = await dbPromise;
	await db.put(storeName, value, key);
};

export const setAllToIndexedDB = async <T>(storeName: string, values: T[]): Promise<void> => {
	const db = await dbPromise;
	const tx = db.transaction(storeName, "readwrite");
	values.forEach((value) => tx.store.put(value));
	await tx.done;
};

export const deleteFromIndexedDB = async (storeName: string, key: string): Promise<void> => {
	const db = await dbPromise;
	await db.delete(storeName, key);
};

export const keepNewerCounts = async (storeName: string, daysToKeep: number): Promise<void> => {
	let deleteFrom = new Date().getTime() - daysToKeep * 24 * 60 * 60 * 1000;
	const db = await dbPromise;
	const allItems = await db.getAll(storeName);
	const itemsToDelete = allItems.filter((c) => new Date(c.itemCount.recordedAt).getTime() < deleteFrom);
	const tx = db.transaction(storeName, "readwrite");
	const store = tx.store;

	// Delete the older records
	for (const key of itemsToDelete) {
		await store.delete(key);
	}

	await tx.done;
};

export const keepLastNItems = async (storeName: string, n: number): Promise<void> => {
	const db = await dbPromise;
	const totalCount = await db.count(storeName);
	const numberOfItemsToDelete = totalCount - n;
	// If there are more than 100 records, delete the oldest ones
	if (totalCount > 100 && numberOfItemsToDelete > 0) {
		const transaction = db.transaction(storeName, "readwrite");
		const store = transaction.objectStore(storeName);

		// Get all the keys sorted by the 'id' (assuming 'id' is the keyPath)
		const keys = await store.getAllKeys();
		const keysToDelete = keys.slice(0, totalCount - n);

		// Delete the oldest records
		for (const key of keysToDelete) {
			await store.delete(key);
		}

		await transaction.done;
	}
};
