import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import localforage from "localforage";
import { combineReducers } from "redux";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import { apiEndpoints } from "~/services/endpoints";
import countReducer from "~/store/count/countReducer";
import errorReducer from "~/store/errorSlice";
import layoutReducer from "~/store/layoutSlice";
import { errorLoggingMiddleware } from "~/store/middleware/errorLoggingMiddleware";
import queueReducer from "~/store/queueSlice";
import settingsReducer from "~/store/settingsSlice";
import siteReducer from "~/store/siteSlice";

const persistConfig = {
	key: "redux",
	storage: localforage,
	// Optionally, you can specify which parts of the state to persist
	whitelist: ["queue", "site", "error", "settings"],
	// blacklist: ["api"], // everything except ui will be persisted
};

const reducer = combineReducers({
	layout: layoutReducer,
	site: siteReducer,
	count: countReducer,
	error: errorReducer,
	settings: settingsReducer,
	queue: queueReducer,
	[apiEndpoints.reducerPath]: apiEndpoints.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
			.concat(apiEndpoints.middleware)
			.concat(errorLoggingMiddleware),
	devTools: process.env.NODE_ENV === "development",
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
