import { useContext } from "react";

import { Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

import { AuthContext } from "~/auth";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDate } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { JobDocument, useWorkOrdersQuery, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";

interface SelectWorkorderProps {
	handleInputChange: (workOrderId: string) => void;
	jobs: JobDocument[];
	workOrderStatuses: WorkOrderStatus[];
}

const SelectWorkorder = ({ handleInputChange, jobs, workOrderStatuses }: SelectWorkorderProps) => {
	const viewType = useAppSelector((state) => state.layout.viewType);
	const authContext = useContext(AuthContext);

	const jobIds = jobs.map((job) => job.id);

	let workOrdersVariables: WorkOrdersQueryVariables = {
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: workOrderStatuses,
		first: 50,
		jobId: jobIds,
	};

	const { data: workOrders, isLoading: workOrdersLoading, error: workOrdersError } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (workOrdersLoading) return <div>Loading work orders...</div>;

	if (workOrdersError) return <div>Error: Getting WorkOrders</div>;

	const getWorkorder = (job: JobDocument) => {
		return workOrders?.workOrders?.nodes?.find(
			//(wo) => wo.jobId == job.id && (wo.workOrderStatus === WorkOrderStatus.New || wo.workOrderStatus === WorkOrderStatus.InProgress)
			(wo) => wo.jobId == job.id
		);
	};

	const handleRowClick = (
		params: any // GridRowParams
	) => {
		console.log("handleRowClick", params);
		handleInputChange(params.id);
	};

	let workOrderData = jobs
		.map((job) => {
			let workOrder = getWorkorder(job);
			if (!workOrder) return null;
			return {
				id: workOrder.id,
				workOrderNumber: workOrder?.workOrderNumber || "",
				lineNumber: workOrder?.lineNumber || 0,
				item: job.item.name,
				upc: job.item.upc,
				erpId: job.item.erpId,
				name: job.name,
				jobStatus: job.jobStatus,
				workOrderStatus: workOrder.workOrderStatus === "CLOSED" ? "PRODUCED" : workOrder.workOrderStatus,
				jobType: job.jobType,
				locationId: job.locationId,
				unitOfMeasure: job.unitOfMeasure,
				containerQuantity: workOrder.containerQuantity,
				productionQuantity: workOrder.productionQuantity,
				quantityProduced: workOrder.quantityProduced || 0,
				genusCode: job.item.genusCode,
				startDate: workOrder.startDate,
				deliveryDate: job.deliveryDate,
				created: job.created,
				updated: job.updated,
			};
		})
		.filter((item) => item !== null)
		.sort((a, b) => (a.workOrderNumber > b.workOrderNumber ? 1 : -1));

	if (viewType === "table") {
		//create GridColDef based on JobDocument
		const columns: GridColDef[] = [
			{ field: "id", headerName: "id", flex: 1 },
			{ field: "workOrderNumber", headerName: "WorkOrder", flex: 1 },
			{ field: "genusCode", headerName: "Genus", flex: 1 },
			{ field: "lineNumber", headerName: "Line", flex: 1 },
			{ field: "item", headerName: "Item", flex: 1 },
			{ field: "upc", headerName: "UPC", flex: 1 },
			{ field: "erpId", headerName: "erpId", flex: 1 },
			{ field: "name", headerName: "name", flex: 1 },
			{ field: "jobStatus", headerName: "jobStatus", flex: 1 },
			{ field: "workOrderStatus", headerName: "wostatus", flex: 1 },
			{ field: "jobType", headerName: "jobType", flex: 1 },
			{ field: "locationId", headerName: "locationId", flex: 1 },
			{ field: "unitOfMeasure", headerName: "unitOfMeasure", flex: 1 },
			{ field: "containerQuantity", headerName: "containerQuantity", flex: 1 },
			{ field: "productionQuantity", headerName: "productionQuantity", flex: 1 },
			{ field: "quantityProduced", headerName: "quantityProduced", flex: 1 },
			{ field: "startDate", headerName: "startDate", flex: 1, valueFormatter: (value) => (value ? stringFormatDate(value) : "") },
			{ field: "deliveryDate", headerName: "deliveryDate", flex: 1 },
			{ field: "created", headerName: "created", flex: 1 },
			{ field: "updated", headerName: "updated", flex: 1 },
		];

		const columnVisibility: GridColumnVisibilityModel = {
			id: false,
			workOrderNumber: true,
			genusCode: true,
			lineNumber: true,
			item: true,
			upc: true,
			erpId: false,
			name: false,
			jobStatus: false,
			workOrderStatus: true,
			jobType: false,
			locationId: false,
			unitOfMeasure: true,
			containerQuantity: true,
			productionQuantity: true,
			quantityProduced: true,
			startDate: true,
			deliveryDate: false,
			created: false,
			updated: false,
		};

		return (
			<SpacedGridItem maxCols={1}>
				<div style={{ width: "100%" }}>
					<DataGrid rows={workOrderData} columns={columns} autoHeight={true} columnVisibilityModel={columnVisibility} onRowClick={handleRowClick} />
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			{workOrderData.map((wod, i) => {
				if (!wod) return null;
				return (
					<SpacedGridItem key={i}>
						<SelectCard
							onClick={() => {
								handleRowClick({ id: wod.id });
							}}
						>
							<Grid container spacing={1} alignItems="stretch" direction="row">
								<Grid item xs={8}>
									<Typography variant="h5" component="span" color="textPrimary">
										{wod.item}
									</Typography>
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Chip size="small" label={wod.workOrderStatus} color="default" />
								</Grid>
								<Grid item xs={8}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Workorder:
									</Typography>{" "}
									{wod.workOrderNumber}{" "}
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Produced:
									</Typography>{" "}
									{wod.quantityProduced || "?"}
								</Grid>
								<Grid item xs={8}>
									<Typography variant="inherit" component="span" color="textSecondary">
										UPC:
									</Typography>{" "}
									{wod.upc}
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Target:
									</Typography>{" "}
									{`${wod.containerQuantity}`}
								</Grid>
							</Grid>
						</SelectCard>
					</SpacedGridItem>
				);
			})}
		</>
	);
};

export default SelectWorkorder;
