import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";

export interface DialogActionButtons {
	children: React.ReactNode;
	props: ButtonProps;
}

interface ModalDialogProps {
	title: string;
	actions?: DialogActionButtons[] | null;
	open: boolean;
	showCloseButton?: boolean;
	close: () => void;
	children?: React.ReactNode;
}
const ModalDialog: React.FC<ModalDialogProps & React.ComponentProps<typeof Dialog>> = ({
	title,
	actions,
	open,
	showCloseButton,
	close,
	children,
	...otherProps
}) => {
	const actionButtons = actions
		? actions.map(
				(action, i) =>
					action.props && (
						<Button key={i} variant="contained" {...action.props}>
							{action.children}
						</Button>
					)
		  )
		: null;

	return (
		<Dialog
			sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			open={open}
			onClose={close}
			closeAfterTransition
			PaperProps={{
				sx: { width: "100%" },
			}}
			disableEnforceFocus={true}
			disableScrollLock={true}
			{...otherProps}
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<>
					{showCloseButton && (
						<IconButton
							aria-label="close"
							onClick={close}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<Icon>close</Icon>
						</IconButton>
					)}
					{children}
				</>
			</DialogContent>
			{actionButtons && actionButtons.length > 0 ? (
				<DialogActions sx={{ padding: (theme) => theme.spacing(2) + " " + theme.spacing(3) }}>{actionButtons}</DialogActions>
			) : null}
		</Dialog>
	);
};

export default ModalDialog;
