import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocationDocument } from "~/services/graphql";

// Define a type for the slice state
interface SiteState {
	required?: boolean;
	location: LocationDocument | undefined;
}

const initialState: SiteState = {
	required: true,
	location: undefined,
};

export const siteSlice = createSlice({
	name: "site",
	initialState,
	reducers: {
		updateSiteRequired: (state, action: PayloadAction<boolean>) => {
			state.required = action.payload;
		},
		updateSite: (state, action: PayloadAction<LocationDocument | null>) => {
			if (!action.payload || action.payload === null) {
				delete state.location;
			} else {
				if (!state.location) {
					state.location = action.payload;
				} else {
					Object.assign(state.location, action.payload);
				}
			}
		},
	},
});

export const { updateSite, updateSiteRequired } = siteSlice.actions;

export default siteSlice.reducer;
